<template>
  <main class="main-grid">
    <section class="new-btn">
      <SidenavBtn :title="'Add Organization'" :context="1" @event="create" />
    </section>

    <section class="delete-btn">
      <SidenavBtn :title="'Delete Organization'" :setModal="''" :context="0" />
    </section>

    <section class="child-route">
      <router-view />
    </section>
  </main>
</template>

<script>
import SidenavBtn from "../components/utils/SidenavBtn.vue";
export default {
  components: { SidenavBtn },
  data() {
    return {};
  },
  methods: {
    create() {
      this.$router.push({ name: "CreateOrganization" });
    },
  },
  mounted() {
    //TEMP!!!!!!!!!!!!!!!!!!!!!!
    this.$router.push({ name: "CreateOrganization" });
  },
};
</script>

<style scoped>
.main-grid {
  display: grid;
  height: 100%;
  width: 100%;
  gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(12, 1fr);
  padding-inline: 1rem;
  overflow: auto;
}
.new-btn {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
}
.delete-btn {
  grid-column: 1 / span 1;
  grid-row: 11 / span 1;
  align-self: end;
  margin-bottom: 8px;
}
.child-route {
  grid-column: 2 / span 5;
  grid-row: 1 / span 11;
}
</style>